import React from "react";
import PropTypes from "react";
import Button from "./ButtonClass";

const HerbFinished = ({ mainButtonAction }) => {
  return (
    <div className="herbFinished">
      <Button
        className="mainAction"
        label="Clear results"
        eventAction={mainButtonAction}
      />
    </div>
  );
};

HerbFinished.propTypes = {
  mainButtonAction: PropTypes.func,
};

export default HerbFinished;
