import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledHeading, StyledParagraph } from "./TextComponents";

const ListContainerOuter = styled.ol`
display: flex;
flex-wrap: wrap;
`;
const ListContainer = styled.ol``;
const StyledResultListPlan = styled.li`
  list-style: lower-alpha;
  margin: 1rem;
`;
const StyledResultListHerb = styled.li``;

// result object preprocessing to render only the required information
// extract high score and result array properties from the result object
const HerbResults = ({ results }) => {
  console.log("highScore: ", results.highScore);
  console.log("bestMatches: ", results.bestMatches);
  // calculate percentage of highscore to ideal highscore of 400
  let percentageHighScore = (highScore) => {
    let calc = (highScore / 400) * 100;
    return calc;
  };

  return (
    <div>
      <StyledHeading as="h2">Results</StyledHeading>
      <StyledParagraph>
        All {results.bestMatches.length} planting plans shown in the following
        give the same score, a {percentageHighScore(results.highScore)} best
        match:
      </StyledParagraph>
      <ListContainerOuter>
        {results.bestMatches.map((bestMatchObject) => (
          <StyledResultListPlan key={bestMatchObject.id}>
            <ListContainer>
              {bestMatchObject.bestMatchArray.map((herb) => (
                <StyledResultListHerb key={bestMatchObject.id + "_" + herb.id}>
                  {herb.name}
                </StyledResultListHerb>
              ))}
            </ListContainer>
          </StyledResultListPlan>
        ))}
      </ListContainerOuter>
    </div>
  );
};

HerbResults.propTypes = {
  results: PropTypes.object,
  // highScore: PropTypes.number,
  // bestMatches: PropTypes.array,
};

export default HerbResults;
