import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledHeading } from "./TextComponents";

// the styled component must be defined outside the wrapper function.
// Otherwise, this will drastically reduce rendering speed!
const StyledHeader = styled.header`
   background: $({theme}) => theme.generalBackGroundColor;
   padding: 0.5em, 0;
 `;

const Header = ({ title }) => {
  return (
    <StyledHeader>
      <StyledHeading as="h1">{title}</StyledHeading>
    </StyledHeader>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
