/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "react";
import styled from "styled-components";

// use object desctructuring to assign theme property from props and
// assign it to a local variable
const StyledButton = styled.button`
  position: relative;
  display: inline-block;
  margin: 20px;
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 0.5em 1em;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  color: ${({ theme }) => theme.mainActionColor};
  background: transparent;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.3;
  }
  &:active {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
      -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
      inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  }
`;

class Button extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledButton onClick={this.props.eventAction}>
        {this.props.label}
      </StyledButton>
    );
  }
}

Button.propTypes = {
  label: PropTypes.string,
};

export default Button;
