import styled from "styled-components";

//
const StyledHeading = styled.div`
  font-size: ${({ as }) =>
    as === "h1" ? "1.5rem" : as === "h2" ? "1.2rem" : "1rem"};
  color: ${({ as, theme }) =>
    as === "h1"
      ? theme["h1Color"]
      : as === "h2"
      ? theme.hTwoColor
      : theme.generalFontColor};
`;

const StyledParagraph = styled.p`
`;

export { StyledHeading, StyledParagraph };
export default StyledParagraph;
