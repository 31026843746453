import React from "react";
import PropTypes from "react";
import Button from "./ButtonClass";

const HerbInput = ({ mainButtonAction }) => {
  return (
    <div className="herbInput">
      <Button
        className="startHerbCalc"
        label="Start"
        eventAction={mainButtonAction}
      />
    </div>
  );
};

HerbInput.propTypes = {
  mainButtonAction: PropTypes.func,
};

export default HerbInput;
