import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import Header from "./Header";
import HerbCalc from "./HerbCalc";
import HerbInfo from "./HerbInfo";
import HerbInput from "./HerbInput";
import HerbResults from "./HerbResults";
import HerbFinished from "./HerbFinished";
import { theme1 } from "./Themes";

// HerbTowerRun runs the HerbTower component
// When it is finished, it can be started again,
// with unmounting / remounting the component
const HerbTowerRun = (props) => {
  const [herbStatus, setHerbStatus] = useState("notStarted");
  // results of the component
  const [herbResults, setHerbResults] = useState({});

  // main recursion function runs in separate worker
  // takes longer time, will be run in a separate thread
  // main function, called when the main action button is clicked
  // calls main recursion function in separate worker
  const createPlantingPlan = () => {
    const worker = new Worker("./createHerbCombinationRecursion.js", {
      type: "module",
    });
    // tell worker to start the function
    let promisePlantingPlan = new Promise(function (resolve) {
      setHerbStatus("inProgress");
      worker.postMessage("startFunction");
      worker.addEventListener("message", (event) => {
        if (Object.prototype.hasOwnProperty.call(event.data, "bestMatches")) {
          setHerbResults(event.data);
          setHerbStatus("finished");
          resolve();
        }
      });
    });

    // wrapper function to add a timeout to the promise
    // could also be made generic and reused for other promises
    const promiseTimeout = (promise, ms) => {
      let timer;
      let timeout = new Promise((resolve, reject) => {
        timer = setTimeout(() => {
          reject("Time out reached after 10 seconds");
        }, ms);
      });
      return Promise.race([promise, timeout])
        .then((result) => {
          clearTimeout(timer);
          return result;
        })
        .catch((result) => {
          alert(result);
          clearTimeout(timer);
          return result;
        });
    };
    promiseTimeout(promisePlantingPlan, 10 * 1000);
  };

  return (
    <>
      <Header title="Herb Tower App" />
      <HerbInfo />
      {herbStatus === "notStarted" ? (
        <HerbInput mainButtonAction={createPlantingPlan} />
      ) : herbStatus === "inProgress" ? (
        <HerbCalc />
      ) : (
        <>
          <HerbFinished mainButtonAction={props.resetApp} />
          <HerbResults results={herbResults} />
        </>
      )}
    </>
  );
};

HerbTowerRun.propTypes = {
  resetApp: PropTypes.func,
};

const HerbTowerContainer = styled.section`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;
// top level herb tower function
// calls the herb tower app and sets its key
// resetting the key will unmount and mount
// the HerbTowerRun component and thus resetting it
const HerbTower = () => {
  const [herbTowerRunID, setherbTowerRunID] = useState(0);

  return (
    <ThemeProvider theme={theme1}>
      <HerbTowerContainer>
        <HerbTowerRun
          key={herbTowerRunID}
          resetApp={() => setherbTowerRunID(herbTowerRunID + 1)}
        />
      </HerbTowerContainer>
    </ThemeProvider>
  );
};

export default HerbTower;
