import React from "react";
import styled from "styled-components";
import herbTower_small from "../images/herbTower_small.jpg";
import { StyledParagraph } from "./TextComponents";

const StyledDetails = styled.details`
  width: 100%;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 1rem;

  @media (min-width: 600 px) {
    max-width: 60%;
  }
`;
const StyledSummary = styled.summary``;

const HerbInfo = () => {
  return (
    <StyledDetails>
      <StyledSummary>About this app</StyledSummary>
      <StyledParagraph>
        <img
          className="nicePicture"
          src={herbTower_small}
          alt="image of my herb tower"
        ></img>
        I bought a herb tower with ten plant holes and ten herbs.
      </StyledParagraph>
      <StyledParagraph>
        I set up the herb tower and noticed that each plant hole gets a
        different amount of sunlight during the day. In the manual, it said that
        the substrate at the bottom level has more moisture than the top level.
        Of course, the herbs I bought had different requirements in sunlight and
        watering.
      </StyledParagraph>
      <StyledParagraph>
        I thought about which herb should be planted in which hole and it took
        me a considerable amount of time to make up my mind. :-)
      </StyledParagraph>
      <StyledParagraph>
        Then I thought: This is a great opportunity to practice my web
        development skills and program an app. So here it is or at least the
        very first step.
      </StyledParagraph>
      <StyledParagraph>
        The herb data is currently hard coded. (The plants I bought.) The
        parameters of the herb tower are hard coded, too. One of the next steps
        will be to program control elements so that the user can enter custom
        data.
      </StyledParagraph>
      <StyledParagraph>
        So just click the button to start the calculation. The app will display
        all planting plans that give the higest scores. The first herb in a
        planting plan will be planted to the top hole. No. 2 is the hole below
        in the picture. 3 the hole below 2. 4 is the hole to the left. And so
        on.
      </StyledParagraph>
      <StyledParagraph>
        This app uses React and Styled Components.
      </StyledParagraph>
    </StyledDetails>
  );
};

export default HerbInfo;
