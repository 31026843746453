// Themes for Styled Components can be defined as objects or functions
const theme1 = {
  mainActionColor: "#eb5160",
  mainActionBackgroundColor: "#5fad56",
  generalBackgroundColor: "#dfe0e2",
  h1Color: "#eb5160",
  hTwoColor: "#646E78",
  generalFontColor: "#646E78",
};

const theme2 = {
  mainActionColor: "blue",
};

export { theme1, theme2 };
